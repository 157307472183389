body {
  color: #fff;
  background-color: #0e0e0e;
  background-attachment: fixed;
  background-image: url("./assets/images/intro.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
a {
  text-decoration: none !important;
}
a:focus,
button:focus,
.custom-control-label::before {
  outline: 0 !important;
  box-shadow: none !important;
}
.x-small {
  font-size: x-small;
}
.font-normal {
  font-size: medium;
}
.ml-neg1 {
  margin-left: -0.25em;
}
.mr-neg1 {
  margin-right: -0.25em;
}
.scratched {
  text-decoration: line-through;
}
.underlined {
  text-decoration: underline;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-help {
  cursor: help;
}
.one-click-select {
  -webkit-touch-callout: all;
  -webkit-user-select: all;
  -khtml-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.opacity-0 {
  opacity: 0;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-6 {
  opacity: 0.6;
}
.opacity-1 {
  opacity: 1;
}
.opacity-transition {
  -moz-transition: opacity 200ms ease-out;
  -webkit-transition: opacity 200ms ease-out;
  -ms-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
  opacity: 1;
}
.intro {
  background-attachment: fixed;
  background-image: url("./assets/images/intro.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.intro.alt {
  background-image: url("./assets/images/intro-alt.svg");
}
.text-danger-light {
  color: #ffadad;
}
.text-success-light {
  color: #40f76a;
}
.text-info-light {
  color: #aeebf5;
}
.text-info-dark {
  color: #107281;
}
.text-green {
  color: #7eaf02 !important;
}
.text-light-green {
  color: #c8e381 !important;
}
.text-lighter-green {
  color: #d5e0b9b2 !important;
}
.text-light-red {
  color: #f86d6d;
}
.text-gray {
  color: #979797 !important;
}
.text-new-dark {
  color: #0e0e0e !important;
}
.text-new-dark:hover {
  color: #2e2e2e !important;
}
.text-light-gray {
  color: #e0e0e0 !important;
}
.text-faded {
  color: rgba(0, 0, 0, 0.6);
}
.nav-tabs .nav-link.active {
  color: #dee2e6 !important;
  background-color: transparent !important;
  border-color: #dee2e6 #dee2e6 #0e0e0e !important;
}
.nav-tabs.info span {
  border: 1px solid transparent;
  background-color: transparent !important;
}
.nav-tabs.info span.active {
  color: #17a2b8 !important;
  background-color: transparent !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}
.active-border a {
  border-bottom: 1px solid transparent;
}
.active-border a.active {
  color: #fff !important;
  border-bottom: 1px solid #17a2b8;
  border-radius: 0;
  background-color: transparent !important;
}
.active-border.info a.active {
  color: #17a2b8 !important;
}
.active-info-trans-bold a.active {
  color: #17a2b8 !important;
  font-weight: bold;
  background-color: transparent !important;
}
.active-info-trans a.active {
  color: #17a2b8 !important;
  background-color: transparent !important;
}
.active-info a.active {
  color: #fff !important;
  background-color: #17a2b8 !important;
}
.active-trans a.active {
  background-color: transparent !important;
}
.active-light a.active {
  color: #fff !important;
  background-color: transparent !important;
}

.hover-border-bottom {
  border-bottom: 1px solid transparent;
  border-radius: 0;
}
.hover-border-bottom.info:hover {
  border-bottom-color: #17a2b8;
}
.hover-info.btn-outline-light:hover {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  background-color: transparent !important;
}
.hover-light.btn-outline-info:hover {
  color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  background-color: transparent !important;
}
.hover-noborder:hover {
  border-color: transparent !important;
}
.hover-white a:hover {
  color: #fff !important;
}
.hover-light:hover {
  color: #f8f9fa !important;
}
.hover-dark:hover {
  color: #343a40 !important;
}
.hover-info:hover,
a.hover-info:hover {
  color: #17a2b8 !important;
}
.hover-dark-info:hover {
  color: #117180 !important;
}
.hover-light-info:hover,
a.hover-light-info:hover {
  color: #68e4f7 !important;
}
.hover-light-green:hover,
a.text-light-green:hover {
  color: #7eaf02 !important;
}
.hover-green:hover,
a.text-green:hover {
  color: #4a6603 !important;
}
.hover-bg-info:hover {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-radius: 0.25rem;
}
.hover-bg-light-green:hover,
.hover-bg-light-green:hover * {
  color: #343a40 !important;
  background-color: #c8e381 !important;
  border-radius: 0.25rem;
}
a.items-info.active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #f8f9fa;
}
a.items-info.active:hover {
  background-color: rgba(23, 162, 184, 0.5);
  color: #f8f9fa !important;
}
a.items-info:hover {
  background-color: transparent;
  color: #17a2b8 !important;
}

a.items-light-green.active {
  background-color: #c8e381;
  border-color: #c8e381;
  color: #343a40 !important;
}
a.items-light-green.active:hover {
  background-color: rgba(200, 227, 129, 0.5);
  border-color: rgba(0, 0, 0, 0.125);
  color: #495057;
}
a.items-light-green:hover {
  background-color: rgba(200, 227, 130, 0.3);
}

.shadow-dark {
  /*background-color: #d8d8d8 !important;*/
  box-shadow: 1px 2px 6px #0e0e0e;
}

.bg-new-darker {
  background-color: rgb(10, 10, 10);
}
.bg-new-dark {
  background-color: #0e0e0e;
}
.bg-darker {
  background-color: #31363b;
}
.bg-grey {
  background-color: #f0f1f2 !important;
}
.bg-dark-grey {
  background-color: #d8d8d8 !important;
}
.bg-light-green {
  background-color: #c8e381;
}
.bg-grad-dark-info {
  background-image: linear-gradient(#343a40, #17a2b8);
}
.bg-grad-info-white {
  background-image: linear-gradient(#17a2b8, #ffffff);
}
.bg-grad-white-info {
  background-image: linear-gradient(#ffffff, #17a2b8);
}
.bg-grad-white-info-2 {
  background-image: linear-gradient(#ffffff, #cdf4fa);
}
.bg-grad-white-grey {
  background-image: linear-gradient(#ffffff, #dedede);
}
.bg-grad-white-green {
  background-image: linear-gradient(#ffffff, #c8e381);
}
.bg-grad-white-dark {
  background-image: linear-gradient(#ffffff, #343a40);
}
.bg-grad-dark-white {
  background-image: linear-gradient(#343a40, #ffffff);
}

.bg-transp {
  background-color: transparent;
}
.bg-transparent-transition {
  -moz-transition: background-color 450ms ease-in;
  -webkit-transition: background-color 450ms ease-in;
  -ms-transition: background-color 450ms ease-in;
  -o-transition: background-color 450ms ease-in;
  transition: background-color 450ms ease-in;
  background-color: transparent;
}
.bg-image {
  background-image: url("./assets/images/bgImg.png");
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  height: 50vh;
}
.bg-img-logo {
  background-attachment: local;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.btn-outline-light-green {
  color: #c8e381;
  border-color: #c8e381;
}
.btn-outline-light-green:hover {
  color: #343a40;
  background-color: #c8e381;
}
.btn-light-green {
  color: #2e2e2e;
  background-color: #c8e381;
  border-color: #c8e381;
}
.btn-light-green:hover {
  color: #c8e381;
  background-color: #2e2e2e;
}
.btn-light-red {
  color: #343a40;
  background-color: #f86d6d;
  border-color: #f86d6d;
}
.btn-light-red:hover {
  color: #f86d6d;
  background-color: #343a40;
}
.btn-new-dark {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.btn-new-dark:hover {
  color: #17a2b8;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.border-dark-gray {
  border-color: #474d56 !important;
}
.border-light-green {
  border-color: #c8e381 !important;
}
.border-bottom-hover-green:hover {
  border-bottom: 1px solid #c8e381 !important;
}
.border-bottom-transparent {
  border-bottom: 1px solid transparent;
}
.border-new-dark {
  border-color: #0e0e0e !important;
}
.table {
  margin-bottom: 0;
}
.table tr:first-child,
.table tr:first-child td {
  border-top: 0 solid;
}
.sortable-table th,
th.sortable {
  cursor: pointer;
}
.sortable-table th:hover,
th.sortable:hover {
  color: #17a2b8;
}
.table-new-dark {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #343a40;
}
.table-new-dark tr.selected {
  color: rgb(255, 248, 148);
  background-color: #092d33;
}
.table-new-dark.table-hover tr:hover {
  color: #17a2b8;
  /* background-color: #deeff1; */
}

tr.no-hover:hover,
.table-new-dark.table-hover tr.no-hover:hover {
  color: inherit !important;
  background-color: inherit !important;
}

.table .thead-new-dark th {
  color: #fff;
  background-color: #2e2e2e;
  /* border-color: #454d55; */
  border-color: #2e2e2e;
}
thead.border-bottom tr th {
  border-bottom: 1px solid;
}

.table-new-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-new-dark td {
  border-color: #343a40;
}
.table-light-blue {
  color: #343a40;
  background-color: #e5f1f0;
}

.table-light-blue.table-hover tr:hover {
  background-color: rgba(255, 248, 148, 0.5);
}
.table-light-gray {
  color: #343a40;
  background-color: rgb(250, 255, 238);
}

.table-light-gray.table-hover tr:hover {
  background-color: rgba(200, 227, 129, 0.2);
}
.table.pricing th,
.table.pricing td {
  padding-left: 16px;
  padding-right: 16px;
}
/* pagination */
.page-link {
  color: #6c757d;
  background-color: #0e0e0e;
  border-color: #343a40;
}
.page-link:hover {
  color: #fff;
  background-color: #107281;
  border-color: #343a40;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #17a2b8;
  border-color: #343a40;
}
.table-responsive::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.table-responsive::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.4);
  background-color: #0e0e0e;
  border-radius: 0.25em;
}
.table-responsive::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 0.25em;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}
.table-responsive::-webkit-scrollbar-corner,
.scroll::-webkit-scrollbar-corner,
.overflow-auto::-webkit-scrollbar-corner {
  background: transparent;
}
.white.table-responsive::-webkit-scrollbar-track,
.white.scroll::-webkit-scrollbar-track,
.white.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}

.white.table-responsive::-webkit-scrollbar-thumb,
.white.scroll::-webkit-scrollbar-thumb,
.white.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-hr {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.custom-hr:after {
  background: -moz-linear-gradient(to right, #343a40, #107281, #127e8f, #107281, #343a40);
  background: -webkit-linear-gradient(to right, #343a40, #107281, #127e8f, #107281, #343a40);
  background: -ms-linear-gradient(to right, #343a40, #107281, #127e8f, #107281, #343a40);
  background: linear-gradient(to right, #343a40, #107281, #127e8f, #107281, #343a40);
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 1px 0 0 0;
}
.custom-hr.alt {
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.custom-hr.alt:after {
  background: -moz-linear-gradient(to right, #ffffff, #b1cdd1, #b1cdd1, #b1cdd1, #ffffff);
  background: -webkit-linear-gradient(to right, #ffffff, #b1cdd1, #b1cdd1, #b1cdd1, #ffffff);
  background: -ms-linear-gradient(to right, #ffffff, #b1cdd1, #b1cdd1, #b1cdd1, #ffffff);
  background: linear-gradient(to right, #ffffff, #b1cdd1, #b1cdd1, #b1cdd1, #ffffff);
}
.major:after {
  background: -moz-linear-gradient(to right, #fff, #000, #fff);
  background: -webkit-linear-gradient(to right, #fff, #000, #fff);
  background: -ms-linear-gradient(to right, #fff, #000, #fff);
  background: linear-gradient(to right, #fff, #000, #fff);

  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}
.major.alt:after {
  background: -moz-linear-gradient(to right, #343a40, #fff, #343a40);
  background: -webkit-linear-gradient(to right, #343a40, #fff, #343a40);
  background: -ms-linear-gradient(to right, #343a40, #fff, #343a40);
  background: linear-gradient(to right, #343a40, #fff, #343a40);
  transform: scaleX(0);
  animation: scaleIn 1s ease-in 0s forwards;
}

.major {
  position: relative;
}
.exchange-logo {
  width: 250px;
}
.logo {
  display: inline-block;

  /*border-radius: 100%;*/
  /*padding: 13px;*/
  /*border: solid 1px #343a40;*/
}
.logo img {
  width: 100px;
  height: auto;
}
.w-55 img {
  width: 55px;
  height: auto;
}
.loading-img,
.loading-img-big,
.loading-img-sm {
  display: inline-block;
}
.loading-img-big img {
  width: 70px;
  height: auto;
}
.loading-img img {
  width: 45px;
  height: auto;
}
.loading-img-sm img {
  width: 25px;
  height: auto;
}
.loading:after {
  content: ".";
  animation: dots 1s steps(5, end) infinite;
}
.loading-white {
  display: inline-block;
  cursor: help;
}
.loading-white img {
  width: 20px;
  height: auto;
}

.page-alt {
  margin-top: 55px;
  min-height: calc(100vh - 55px);
}
.page-contact {
  margin: 100px 45px 45px;
  min-height: calc(100vh - 257px);
}

.page {
  margin: 55px auto 0;
}

.footer > .container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
  bottom: 0;
}

.tooltip-parent {
  position: relative;
}
.tooltip-parent .tooltip-on-hover {
  display: none;
  position: absolute;
  z-index: 10000;
}
.tooltip-parent:hover .tooltip-on-hover {
  display: block;
}
.modal {
  overflow-y: auto;
  color: #333333;
}
.modal-large {
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
}
.modal.show {
  -moz-transition: opacity 160ms ease-out;
  -webkit-transition: opacity 160ms ease-out;
  -ms-transition: opacity 160ms ease-out;
  -o-transition: opacity 160ms ease-out;
  transition: opacity 160ms ease-out;
}
.modal-content-slide-down {
  animation-name: animatetop;
  animation-duration: 0.1s;
}

.overlay-div {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  z-index: 10;
}
.eta {
  position: absolute;
  font-size: 11px;
  top: 12px;
  left: 2px;
}
#dragX {
  width: 7px;
  cursor: col-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: -moz-linear-gradient(to right, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: -webkit-linear-gradient(to right, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: -ms-linear-gradient(to right, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: linear-gradient(to right, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
}
#dragY {
  height: 7px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: row-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: -moz-linear-gradient(to top, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: -webkit-linear-gradient(to top, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: -ms-linear-gradient(to top, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
  background: linear-gradient(to top, #0e0e0e, #107281, #0e0e0e, #107281, #0e0e0e);
}

.switch1,
.switch2 {
  display: none;
}
.show-switch1 .switch1 {
  display: block;
}
.show-switch2 .switch2 {
  display: block;
}

.drop-down-search {
  position: relative;
}
.drop-down-search a {
  white-space: pre;
  z-index: 100;
  height: 30px;
  padding: 4px 8px;
}
.hide-first a:first-child {
  display: none !important;
}

input[type="range"].red::-webkit-slider-thumb {
  height: 11px;
  width: 11px;
  background: #dc3545 !important;
}
input[type="range"].red::-moz-range-thumb {
  height: 11px;
  width: 11px;
  background: #dc3545 !important;
}
input[type="range"].red::-ms-thumb {
  height: 11px;
  width: 11px;

  background: #dc3545 !important;
}
input[type="range"].green::-webkit-slider-thumb {
  height: 11px;
  width: 11px;
  background: #28a745 !important;
}
input[type="range"].green::-moz-range-thumb {
  height: 11px;
  width: 11px;
  background: #28a745 !important;
}
input[type="range"].green::-ms-thumb {
  height: 11px;
  width: 11px;
  background: #28a745 !important;
}
/*input range*/
input[type="range"].green,
input[type="range"].red {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
input[type="range"].green::-webkit-slider-thumb,
input[type="range"].red::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"].green:focus,
input[type="range"].red:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
input[type="range"].green::-ms-track,
input[type="range"].red::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"].green::-webkit-slider-runnable-track,
input[type="range"].red::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: none;
  border: none;
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
}
input[type="range"].green:focus::-webkit-slider-runnable-track,
input[type="range"].red:focus::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
}
input[type="range"].green::-moz-range-track,
input[type="range"].red::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: none;
  border: none;
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
}
input[type="range"].green::-ms-track,
input[type="range"].red::-ms-track {
  width: 100%;
  height: 2px;
  cursor: none;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"].green::-ms-fill-lower,
input[type="range"].red::-ms-fill-lower {
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
  border: none;
}
input[type="range"].green:focus::-ms-fill-lower,
input[type="range"].red:focus::-ms-fill-lower {
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
}
input[type="range"].green::-ms-fill-upper,
input[type="range"].red::-ms-fill-upper {
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
  border: none;
}
input[type="range"].green:focus::-ms-fill-upper,
input[type="range"].red:focus::-ms-fill-upper {
  background: linear-gradient(to right, #dc3545, #28a745);
  background: -moz-linear-gradient(to right, #dc3545, #28a745);
  background: -webkit-linear-gradient(to right, #dc3545, #28a745);
  background: -ms-linear-gradient(to right, #dc3545, #28a745);
  background: linear-gradient(to right, #dc3545, #28a745);
}
input:focus,
.uneditable-input:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: none !important;
}
textarea:focus,
input.focus-info:focus,
.uneditable-input.focus-info:focus {
  outline: none !important;
  border-color: rgba(23, 162, 184, 0.8) !important;
  box-shadow: 0 1px 1px rgba(23, 162, 184, 0.075), 0 0 8px rgba(23, 162, 184, 0.6) !important;
}

textarea.dark:focus,
input.dark:focus,
.uneditable-input.dark:focus {
  outline: none !important;
  box-shadow: 0 0 1pt 1pt #17a2b8 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-calendar-picker-indicator {
  background-color: #17a2b8;
  border-radius: 0.25em;
}
input::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}
input[type="text"],
input[type="number"],
input[type="datetime-local"] {
  -moz-appearance: textfield;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  width: 220px;
  height: 24px;
  color: #fff;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}
input.strategy {
  border-bottom: 1px solid #f8f9fa !important;
  width: 100px !important;
  color: #c8e381 !important;
  font-style: italic;
}
input.strategy:focus,
input.strategy:hover,
input.strategy:hover::placeholder,
input.strategy.optional:hover::placeholder,
input.strategy.required:hover::placeholder,
input.strategy.default:hover::placeholder,
input.strategy:focus::placeholder {
  color: #7eaf02 !important;
  opacity: 1;
}

input.setup-info {
  color: #17a2b8 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
input.setup-info:focus,
input.setup-info:hover,
input.setup-info:hover::placeholder,
input.setup-info:focus::placeholder,
input.setup-info.optional:hover::placeholder,
input.setup-info.optional:focus::placeholder {
  border-bottom: 1px solid #17a2b8 !important;
  background-color: #17a2b8 !important;
  color: #fff !important;
  opacity: 1; /* Firefox */
  border-radius: 0.25rem;
  border-color: #17a2b8 !important;
}
input.search-bar {
  border-bottom: 1px solid transparent;
  color: #17a2b8 !important;
}
input.search-bar::placeholder {
  color: #17a2b8 !important;
}
input.search-bar:focus,
input.search-bar:hover {
  border-bottom-color: #17a2b8 !important;
  color: #dee2e6 !important;
}
input.search-bar:hover::placeholder,
input.search-bar:focus::placeholder {
  color: #dee2e6 !important;
}

input.border-bottom-transparent,
input.focus-border-bottom-light,
input.hover-border-bottom-light,
input.focus-border-bottom-info,
input.hover-border-bottom-info {
  border-bottom: 1px solid transparent;
}

input.border-bottom-light,
input.focus-border-bottom-light:focus,
input.hover-border-bottom-light:hover {
  border-bottom: 1px solid #f8f9fa !important;
}
input.border-bottom-info,
input.focus-border-bottom-info:focus,
input.hover-border-bottom-info:hover {
  border-bottom: 1px solid #17a2b8 !important;
}
input.text-light-green,
input.text-light-green::placeholder,
input.hover-light-green:hover,
input.hover-light-green:hover::placeholder,
input.focus-light-green:focus,
input.focus-light-green:focus::placeholder {
  color: #7eaf02 !important;
  opacity: 1; /* Firefox */
}
input.text-green,
input.text-green::placeholder,
input.hover-green:hover,
input.hover-green:hover::placeholder,
input.focus-green:focus,
input.focus-green:focus::placeholder {
  color: #c8e381 !important;
  opacity: 1; /* Firefox */
}
input.text-info,
input.text-info::placeholder,
input.hover-info:hover,
input.hover-info:hover::placeholder,
input.focus-info:focus,
input.focus-info:focus::placeholder {
  color: #17a2b8 !important;
  opacity: 1; /* Firefox */
}
input.text-light,
input.text-light::placeholder,
input.hover-light:hover,
input.hover-light:hover::placeholder,
input.focus-light:focus,
input.focus-light:focus::placeholder {
  color: #f8f9fa !important;
  opacity: 1; /* Firefox */
}
input.text-dark,
input.text-dark::placeholder,
input.hover-dark:hover,
input.hover-dark:hover::placeholder,
input.focus-dark:focus,
input.focus-dark:focus::placeholder {
  color: #343a40 !important;
  opacity: 1; /* Firefox */
}
input.invalid,
input.invalid:focus {
  color: #dc3545 !important;
}
input.required::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffadad !important;
}
input.optional::placeholder,
input.default::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797 !important;
}

input.btn-bottom-outline-info,
input.btn-bottom-outline-info:focus {
  color: #17a2b8 !important;
  border-bottom: 1px solid #17a2b8 !important;
}
input.btn-bottom-outline-info::placeholder,
input.btn-bottom-outline-info:focus::placeholder {
  color: #17a2b8 !important;
}
input.btn-bottom-outline-info:hover::placeholder {
  color: #117180 !important;
}
input.btn-bottom-outline-info:hover {
  color: #117180 !important;
  border-bottom: 1px solid #117180 !important;
}
input.btn-outline-info,
input.btn-outline-info:focus {
  color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  border-radius: 0.25em;
  padding: 14px 0 !important;
  font-size: 80%;
}
input.btn-outline-info::placeholder,
input.btn-outline-info:focus::placeholder {
  color: #17a2b8 !important;
}
input.btn-outline-info:hover,
input.btn-outline-info:hover::placeholder {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  border-radius: 0.25em;
}
input.btn-outline-gray,
input.btn-outline-gray:focus {
  width: 220px;
  height: 24px;
  text-align: center;
  color: #495057 !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 0.25em;
  padding: 16px 0 !important;
}
input.form-control {
  color: #495057 !important;
  border: 1px solid #d6d6d6 !important;
}
input.btn-outline-gray:hover,
input.form-control:hover,
input.form-control:focus {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  border-radius: 0.25em !important;
}
input.text-left {
  text-align: left;
}
input.text-right {
  text-align: right;
}
input.min-w-150 {
  width: 150px !important;
}
input.w-25-px {
  width: 25px !important;
}
input.w-35 {
  width: 35px !important;
}
input.w-40 {
  width: 40px !important;
}
input.w-50 {
  width: 50px !important;
}
input.w-80 {
  width: 80px !important;
}
input.w-75-p {
  width: 75% !important;
}
input.w-100-p {
  width: 100% !important;
}
input.w-100-px {
  width: 100px !important;
}
input.w-150 {
  width: 150px !important;
}
input.w-165 {
  width: 165px !important;
}
input.w-400 {
  width: 400px !important;
}
input.h-20 {
  height: 20px !important;
}
input.h-32 {
  height: 32.5px !important;
}
input.h-33 {
  height: 33px !important;
}
input.py-15 {
  padding: 15px 0 !important;
}

input.pb-2 {
  padding-bottom: 8px !important;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: solid 1px #17a2b8;
  background-color: #fff;
}
.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  border: solid 1px #495057;
  background-color: #fff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  border: solid 1px #17a2b8;
  background-color: #17a2b8;
}

.export-strategies {
  max-height: calc(100vh - 400px);
  min-height: 150px;
}
.strategy-edit {
  max-height: calc(100vh - 220px);
  min-height: calc(100vh - 220px);
}

.strategy-choose-ind {
  max-height: calc(100vh - 500px);
  min-height: calc(100vh - 500px);
}
.col-strategy-label {
  width: 6.7em;
  margin-left: 1em;
  white-space: nowrap;
  display: inline-block;
}
.col-strategy-label.alt {
  margin-bottom: 0rem !important;
}
.col-strategy-input {
  display: inline-block;
  width: calc(100% - 8.7em);
  margin-right: 1em;
}
.col-strategy-input.ml-neg1 {
  width: calc(100% - 8.45em);
  margin-right: 0.75em;
}

.col-rule-btn {
  display: inline-block;
  width: 16px;
}
.col-rule {
  display: inline-block;
  width: calc(100% - 24px);
}
.col-setup-nav-tabs {
  width: 140px;
}
.col-setup-nav-title {
  width: calc(100% - 140px);
}
.col-setup-left {
  width: 370px;
}
.col-setup-right {
  width: calc(100% - 370px);
}
.h-lists {
  height: 380px;
}
.h-fullscreen {
  height: 85vh;
}
.h-panel-fullscreen {
  height: calc(100vh - 100px);
}
.h-trading {
  height: calc(100vh - 150px);
}

.h-90 {
  height: 90px;
}
.h-180 {
  height: 180px;
}
.h-250 {
  height: 250px;
}
.h-35-vh {
  height: 35vh;
}
.h-100-vh {
  height: 100vh;
}
.h-100-p {
  height: 100%;
}
.max-h-55-vh {
  max-height: 55vh;
}
.max-h-150 {
  max-height: 150px;
}
.max-h-180 {
  max-height: 180px;
}
.max-h-200 {
  max-height: 200px;
}
.max-h-250 {
  max-height: 250px;
}
.max-h-100 {
  max-height: 100%;
}
.max-h-fullscreen {
  max-height: 70vh;
}
.max-h-60-vh {
  max-height: 60vh;
}
.max-h-lists {
  max-height: 380px;
}
.min-h-lists {
  min-height: 380px;
}
.min-h-150 {
  min-height: 150px;
}
.min-h-220 {
  min-height: 220px;
}

.min-w-25 {
  min-width: 25px;
}
.min-w-50 {
  min-width: 50px;
}
.min-w-80 {
  min-width: 80px;
}
.min-w-100 {
  min-width: 100px;
}
.min-w-120 {
  min-width: 120px;
}
.min-w-130 {
  min-width: 130px;
}
.min-w-150 {
  min-width: 150px;
}
.min-w-200,
.min-w-200-sm {
  min-width: 200px;
}
.min-w-250 {
  min-width: 250px;
}
.min-w-270 {
  min-width: 270px;
}
.min-w-280 {
  min-width: 280px;
}
.min-w-400 {
  min-width: 400px;
}
.min-w-hover-strategy {
  min-width: 600px;
}
.max-w-100 {
  max-width: 100px;
}
.max-w-150 {
  max-width: 150px;
}
.max-w-200 {
  max-width: 200px;
}
.max-w-220 {
  max-width: 220px;
}
.max-w-270 {
  max-width: 270px;
}
.max-w-450 {
  max-width: 450px;
}
.w-30 {
  width: 30px;
}
.w-60 {
  width: 60px;
}
.w-80 {
  width: 80px;
}
.w-100-px {
  width: 100px;
}
.w-105 {
  width: 105px;
}
.w-110 {
  width: 110px;
}
.w-130 {
  width: 130px;
}
.w-150 {
  width: 150px;
}
.w-165 {
  width: 165px;
}
.w-200 {
  width: 200px;
}
.w-220 {
  width: 220px;
}
.w-250 {
  width: 250px;
}
.w-350 {
  width: 350px;
}
.w-custom {
  width: 120px;
}
.w-contact {
  width: 65%;
  margin: auto;
}
img.w-custom {
  max-width: 100%;
  width: 80%;
  height: auto;
  margin: auto;
}
.right-absolute {
  position: absolute;
  right: 0;
  top: 100px;
}
.right-0 {
  right: 0;
}
.right-025 {
  right: 0.25em;
}
.right-1 {
  right: 1em;
}
.right-neg-1 {
  right: -0.5em;
}
.right-bottom-absolute {
  position: absolute;
  right: 0;
  bottom: 0;
}
.left-0 {
  left: 0;
}
.left-1 {
  left: 1em;
}
.bottom-1 {
  bottom: 1em;
}
.top-0 {
  top: 0;
}
.top-5-px {
  top: 5px;
}
.top-2-px {
  top: 2px;
}
.top-3 {
  top: 3em;
}
.top-neg-1 {
  top: -0.5em;
}
.top-right-custom {
  top: -0.1em;
  right: -0.2em;
}
.z-index-big {
  z-index: 100000;
}
.z-index-100 {
  z-index: 100;
}
.z-index-0 {
  z-index: 0;
}
.nav-header {
  display: inline-block;
}
.small-nav {
  display: none;
}
.big-nav {
  display: block;
}

@keyframes scaleIn {
  100% {
    transform: scaleX(1);
  }
}
@keyframes dots {
  0%,
  20% {
    color: #fff;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  40% {
    color: #333333;
    text-shadow: 0.25em 0 0 #fff, 0.5em 0 0 #fff;
  }
  60% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #fff;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #333333, 0.5em 0 0 #333333;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showX {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rotator {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideAwayFromLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slideInFromLeftFull {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRightFull {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bgColorToTrans {
  0% {
    opacity: 0;
    background-color: #c8e381;
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}
@keyframes bgColorToTransInfo {
  0% {
    opacity: 0;
    background-color: #17a2b8;
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}
@keyframes slideInFromBottom200 {
  0% {
    transform: translateY(1300%);
  }
  100% {
    transform: translateY(0);
  }
}

.bg-change {
  animation: bgColorToTrans 0.7s ease-out 0s 1;
}
.bg-change-info {
  animation: bgColorToTransInfo 0.7s ease-out 0s 1;
}
.strech::after {
  transform: scaleX(0);
  animation: scaleIn 1s ease-in 0s forwards;
}
.blink {
  animation: blinker 2s linear infinite;
}
.blink-slow {
  animation: blinker 2.5s linear infinite;
}
.rotate-180 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.rotate {
  animation: rotator 3.5s ease-in-out infinite;
}
.update {
  position: absolute;
  right: 10px;
  font-size: 14pt;
}
.fade-in {
  animation: 0.7s ease-out 0s 1 fadeIn;
}
.fade-in-fast {
  animation: 0.3s ease-out 0s 1 fadeIn;
}
.show-left-right {
  animation: 5s ease-out infinite showX;
}
.slide-away-left {
  animation: slideAwayFromLeft 5s ease-out alternate infinite;
}
.left-slide {
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}
.right-slide {
  animation: 1.5s ease-out 0s 1 slideInFromRight;
}
.top-slide {
  animation: 1.5s ease-out 0s 1 slideInFromTop;
}
.bottom-slide {
  animation: 1.5s ease-out 0s 1 slideInFromBottom;
}

.left-slide1 {
  animation: 0.8s ease-out 0s 1 slideInFromLeft;
}
.left-slide2 {
  animation: 1.6s ease-out 0s 1 slideInFromLeft;
}
.left-slide3 {
  animation: 2.4s ease-out 0s 1 slideInFromLeft;
}
.left-slide4 {
  animation: 3.2s ease-out 0s 1 slideInFromLeft;
}
.left-slide-full {
  animation: 1s ease-out 0s 1 slideInFromLeftFull;
}

.right-slide1 {
  animation: 0.8s ease-out 0s 1 slideInFromRight;
}
.right-slide2 {
  animation: 1.6s ease-out 0s 1 slideInFromRight;
}
.right-slide3 {
  animation: 2.4s ease-out 0s 1 slideInFromRight;
}
.right-slide4 {
  animation: 3.2s ease-out 0s 1 slideInFromRight;
}
.right-slide-full {
  animation: 1s ease-out 0s 1 slideInFromRightFull;
}

.top-slide1 {
  animation: 0.8s ease-out 0s 1 slideInFromTop;
}
.top-slide2 {
  animation: 1.6s ease-out 0s 1 slideInFromTop;
}
.top-slide3 {
  animation: 2.4s ease-out 0s 1 slideInFromTop;
}
.top-slide4 {
  animation: 3.2s ease-out 0s 1 slideInFromTop;
}
.bottom-slide1 {
  animation: 0.8s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide2 {
  animation: 1.6s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide3 {
  animation: 2.4s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide4 {
  animation: 3.2s ease-out 0s 1 slideInFromBottom;
}
.bottom-slide5 {
  animation: 3.5s ease-out 0s 1 slideInFromBottom200;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -moz-transition: opacity 250ms ease-out;
  -webkit-transition: opacity 250ms ease-out;
  -ms-transition: opacity 250ms ease-out;
  -o-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
}

.slide-left-enter {
  opacity: 0.01;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.slide-left-enter-active {
  opacity: 1;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -moz-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-out;
}
.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-left-exit-active {
  opacity: 0.01;
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -moz-transition: all 150ms ease-in;
  -webkit-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  transition: all 150ms ease-out;
}

.slide-left-right-enter {
  opacity: 0.01;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.slide-left-right-enter-active {
  opacity: 1;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -moz-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-out;
}
.slide-left-right-exit {
  opacity: 1;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.slide-left-right-exit-active {
  opacity: 0.01;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -moz-transition: all 150ms ease-in;
  -webkit-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  transition: all 150ms ease-out;
}

.slide-right-left-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}
.slide-right-left-enter-active {
  opacity: 1;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -moz-transition: all 300ms ease-in;
  -webkit-transition: all 300ms ease-in;
  -ms-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-out;
}
.slide-right-left-exit {
  opacity: 1;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.slide-right-left-exit-active {
  opacity: 0.01;
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -moz-transition: all 150ms ease-in;
  -webkit-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  transition: all 150ms ease-out;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 9px;
  border-radius: 4px;
  background: -moz-linear-gradient(to right, #0e0e0e, #0e0e0e, #5ba8b4, #0e0e0e, #0e0e0e);
  background: -webkit-linear-gradient(to right, #0e0e0e, #0e0e0e, #5ba8b4, #0e0e0e, #0e0e0e);
  background: -ms-linear-gradient(to right, #0e0e0e, #0e0e0e, #5ba8b4, #0e0e0e, #0e0e0e);
  background: linear-gradient(to top, #0e0e0e, #0e0e0e, #5ba8b4, #0e0e0e, #0e0e0e);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #6c757d;
  background: #17a2b8;
  cursor: pointer;
}
.slider:hover::-webkit-slider-thumb {
  background: #107281;
}
.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #6c757d;
  background: #17a2b8;
  cursor: pointer;
}
@media (min-height: 900px) {
  .h-custom {
    height: 92vh;
  }
}
@media (max-height: 900px) {
  .h-custom {
    height: 90vh;
  }
  .h-nav {
    max-height: 82vh;
  }
}
@media (max-height: 800px) {
  .h-custom {
    height: 88vh;
  }
  .h-nav {
    max-height: 82vh;
  }
  .max-h-fullscreen {
    max-height: 67vh;
  }
}
@media (max-height: 700px) {
  .h-custom {
    height: 86vh;
  }
  .h-nav {
    max-height: 80vh;
  }
  .h-fullscreen {
    height: 82vh;
  }
  .max-h-fullscreen {
    max-height: 62vh;
  }
  .strategy-choose-ind {
    max-height: calc(100vh - 400px);
    min-height: calc(100vh - 400px);
  }
}
@media (max-height: 600px) {
  .h-custom {
    height: auto;
  }
  .h-nav {
    max-height: auto;
  }
  .max-h-fullscreen {
    max-height: 56vh;
  }
  .strategy-choose-ind {
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 350px);
  }
}
@media (max-height: 500px) {
  .h-trading {
    height: 380px;
  }
  .strategy-choose-ind {
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
  }
}
@media (max-height: 400px) {
  .strategy-edit {
    max-height: none;
    min-height: none;
  }
  .strategy-choose-ind {
    max-height: none;
    min-height: none;
  }
}
@media (min-width: 2010px) {
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media (min-width: 1620px) {
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 80% !important;
  }
}
@media (min-width: 1200px) {
  .border-left-xl {
    border-left: 1px solid #dee2e6 !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
}
@media (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #dee2e6;
  }
  .border-left-lg {
    border-left: 1px solid #dee2e6;
  }
}
@media (min-width: 768px) {
  .table.equal-cols {
    table-layout: fixed;
    width: 100%;
  }
  .border-right-md {
    border-right: 1px solid #dee2e6;
  }
  .border-left-md {
    border-left: 1px solid #dee2e6;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-up-50 {
    width: 50% !important;
  }
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

@media (max-width: 1499px) {
  img.w-custom {
    width: 85%;
  }
  .w-contact {
    width: 80%;
  }
}

@media (max-width: 1199px) {
  img.w-custom {
    width: 100%;
  }
  .w-contact {
    width: 95%;
  }
  .w-lg-85 {
    width: 85% !important;
  }
}

@media (max-width: 991px) {
  .border-top-max-lg {
    border-top: 1px solid #dee2e6;
  }
  .border-bottom-max-lg {
    border-bottom: 1px solid #dee2e6;
  }
  .modal-large {
    max-width: 90%;
  }
  .home-img img {
    top: 120px;
  }
  .w-md-90 {
    width: 90% !important;
  }
  @media (min-height: 900px) {
    .h-mobile {
      max-height: 84vh;
    }
  }
  @media (max-height: 900px) {
    .h-mobile {
      max-height: 82vh;
    }
  }
  @media (max-height: 800px) {
    .h-mobile {
      max-height: 80vh;
    }
  }
  @media (max-height: 700px) {
    .h-mobile {
      max-height: 78vh;
    }
  }
  @media (max-height: 600px) {
    .h-mobile {
      max-height: 74vh;
    }
  }
  @media (max-height: 500px) {
    .h-mobile {
      max-height: 68vh;
    }
  }
  @media (max-height: 400px) {
    .h-mobile {
      max-height: 62vh;
    }
  }
  @media (max-height: 300px) {
    .h-mobile {
      max-height: 52vh;
    }
  }
}

@media (max-width: 967px) {
  #topNavLogoText {
    display: none;
  }
}

@media (max-width: 767px) {
  .display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1;
  }

  .modal-body {
    padding: 0.5rem 0.5rem !important;
  }

  .modal-large {
    max-width: 95%;
  }
  .navbar {
    padding: 0;
  }
  .nav-header {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
    z-index: 1001;
    flex-direction: row;
  }

  .page-contact {
    margin: 80px 25px 25px;
    min-height: calc(100vh - 217px);
  }

  #topNavLogoText {
    display: inline;
  }
  #topNav {
    position: absolute;
    width: 100%;
    display: none;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  #topNav .nav-menu {
    background-image: -moz-linear-gradient(to top, #2e2e2e, #0e0e0e, #2e2e2e);
    background-image: -webkit-linear-gradient(to top, #2e2e2e, #0e0e0e, #2e2e2e);
    background-image: -ms-linear-gradient(to top, #2e2e2e, #0e0e0e, #2e2e2e);
    background-image: linear-gradient(to top, #2e2e2e, #0e0e0e, #2e2e2e);
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 1.5em;
    height: 0.25em;
    line-height: 0.25em;
    background-color: #fff;
    margin: 0.25em 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
  .sub-menu .bar1,
  .sub-menu .bar2,
  .sub-menu .bar3 {
    width: 1.2em;
    height: 0.2em;
    margin: 0em 0 0.2em 0;
    line-height: auto;
    background-color: #17a2b8;
  }
  .change .bar1 {
    -moz-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -webkit-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -ms-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -o-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
  }
  .change .bar2 {
    opacity: 0;
  }
  .change .bar3 {
    -moz-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -webkit-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -ms-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -o-transform: rotate(45deg) translate(-0.375em, -0.375em);
    transform: rotate(45deg) translate(-0.375em, -0.375em);
  }
  .sub-menu .change .bar1 {
    -moz-transform: rotate(-45deg) translate(-0.3em, 0.25em);
    -webkit-transform: rotate(-45deg) translate(-0.3em, 0.25em);
    -ms-transform: rotate(-45deg) translate(-0.3em, 0.25em);
    -o-transform: rotate(-45deg) translate(-0.3em, 0.25em);
    transform: rotate(-45deg) translate(-0.3em, 0.25em);
  }
  .sub-menu .change .bar3 {
    -moz-transform: rotate(45deg) translate(-0.3em, -0.3em);
    -webkit-transform: rotate(45deg) translate(-0.3em, -0.3em);
    -ms-transform: rotate(45deg) translate(-0.3em, -0.3em);
    -o-transform: rotate(45deg) translate(-0.3em, -0.3em);
    transform: rotate(45deg) translate(-0.3em, -0.3em);
  }

  .strategy-edit {
    max-height: none;
    min-height: none;
  }
  .strategy-input {
    max-width: calc(100% - 2.3em);
  }
  .col-setup-left {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 16px;
  }
  .col-setup-right {
    width: 100%;
  }
  .h-custom {
    height: auto;
  }
  .h-trading {
    height: 380px;
  }
  .w-sm-50 {
    max-width: 60%;
  }
  .w-md-50 {
    width: 50%;
    margin: auto;
  }
  .w-custom {
    width: 100px;
  }
  .small-nav {
    display: block;
    z-index: 100;
  }
  .big-nav {
    display: none;
  }
  .right-slide-full,
  .left-slide-full {
    animation: none;
  }
}

@media (max-width: 575px) {
  .update {
    z-index: 1200;
    right: 78px;
    font-size: 16pt;
  }
  label {
    margin-bottom: 0;
  }
  .modal {
    margin: 8px;
    width: calc(100% - 16px);
    max-height: calc(100vh - 16px);
  }
  .modal.large {
    top: 0;
    margin: 0px 4px !important;
    width: 100%;
    max-width: calc(100% - 8px);
    max-height: none;
  }
  .modal-footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .modal-body,
  .modal-header {
    padding: 0.5rem 0.5rem !important;
  }
  .page-contact {
    margin: 55px 0px 0px;
    min-height: calc(100vh - 167px);
    border-radius: 0 !important;
  }
  input.search-bar {
    width: 125px;
  }
  .col-strategy-label {
    margin-left: 0.5em;
  }
  .col-strategy-label {
    margin-bottom: 0.5rem !important;
  }
  .col-strategy-label.alt {
    margin-bottom: 0rem !important;
  }
  .col-strategy-input {
    width: calc(100% - 7.7em);
    margin-right: 0.5em;
  }
  .col-strategy-input.ml-neg1 {
    width: calc(100% - 7.45em);
    margin-right: 0.25em;
  }
  .min-w-sm-300 {
    min-width: 300px;
  }
  .min-w-200 {
    min-width: 180px;
  }
  .min-w-200-sm {
    min-width: 150px;
  }
  .max-w-270 {
    max-width: 170px;
  }
  .w-custom {
    width: 80px;
  }
}
@media (max-width: 475px) {
  .max-w-270 {
    max-width: 100px;
  }
}
@media (max-width: 375px) {
  .max-w-270 {
    max-width: 60px;
  }
  .display-3 {
    font-size: 2.6rem;
    font-weight: 300;
    line-height: 1;
  }
  input.search-bar {
    width: 80px;
  }
}
